export const ApiServices = {
    POSTS: 'get-req-data/posts',
    PHOTOS: 'get-req-data/photos',
    PAGE_DATA:'get-req-data/page-data',
    SECTIONS: 'get-req-data/sections',
    SERVICERESEARCH: 'get-req-data/research-documents-all',
    JOB: 'get-req-data/job-list',
    TEAM: 'get-req-data/team-list',
    CHILD_PAGE: 'get-req-data/child-pages',
    PAGE_POST_DATA: 'get-req-data/page-post-data',
    SEARCH : 'get-req-data/search',
    SEARCHCAMPAIGN : 'get-req-data/campaign-search',
    ALL_CATEGORY: 'get-req-data/all-category',
    CHILD_CATEGORY: 'get-req-data/child-category',
    PRODUCT_DATA: 'get-req-data/product-data',
    ALL_PRODUCTS: 'get-req-data/all-products',
    POST_FORM: 'post-req-data/form-submit',
    // POST_FORM: 'form-fields?form_id=contact-form',
    // SERVICE_FORM: 'get-req-data/form-fields?form_id=our-services-form',
    SERVICE_FORM: 'post-req-data/form-submit',
    // GETINTOUCH_FORM: 'get-req-data/form-fields?form_id=get-in-touch',
    GETINTOUCH_FORM: 'post-req-data/form-submit',
    FIND_DEALER: 'get-req-data/dealer-location',
    // GOOGLE_PLACE_NEARBY:"place/nearbysearch/json"
    SETTINGS: 'get-req-data/settings-data',
    COURSEDATA: 'get-req-data/course-data',
    BlogData: 'get-req-data/blog-data',
    RESEARCHLIST: 'get-req-data/research-list',
    ADDSUBSCRIP: 'post-req-data/add-subscriber',
    SUBMITFORM: 'post-req-data/form-submit',
    INVESTOR: 'get-req-data/investor-data',
    REARCHLISTCAT: 'get-req-data/research-documents',
    HOMEJSON: 'json-data/home'
};
